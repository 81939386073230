<template>
  <div class="pages">
    <h1>Criar novo conveniado - Clube de Serviços</h1>
    <button class="btn outline back-btn" v-on:click="$router.back()">Voltar</button>
    <div class="wrapper">
      <div>
        <label for="type">Tipo</label>
        <select id="type" v-model="item.type">
          <option value="e">Educação</option>
          <option value="i">IES Conveniada</option>
          <option value="s">Saúde</option>
          <option value="o">Outro</option>
        </select>
      </div>
      <div>
        <label for="name">Nome</label>
        <input type="text" id="name" v-model="item.name" placeholder="Nome">
      </div>
      <div>
        <label for="img">Logo</label>
        <input type="file" id="img" v-on:change="imgChanged" accept="image/*" ref="img">
        <p v-if="savingImg">Fazendo upload...</p>
        <img v-if="item.logo" :src="$mediaBaseUrl + item.logo" :alt="item.name">
      </div>
      <div>
        <label for="description">Descrição</label>
        <textarea id="description" v-model="item.description"/>
      </div>
      <div>
        <label for="site">Site</label>
        <input type="text" id="site" v-model="item.site" placeholder="Link do site">
      </div>
      <div>
        <label for="email">Email</label>
        <input type="text" id="email" v-model="item.email" placeholder="Email">
      </div>
      <div>
        <label for="phone">Telefone</label>
        <input type="text" id="phone" v-model="item.phone" placeholder="Telefone">
      </div>
      <div>
        <label for="address">Endereço</label>
        <input type="text" id="address" v-model="item.address" placeholder="Endereço">
      </div>
      <br>
      <button class="btn save-btn" v-on:click="saveItem" :disabled="loading">{{ loading ? 'Salvando...' : 'Salvar' }}</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require('axios').default;

export default {
  name: 'newitem',
  data() {
    return {
      loading: false,
      savingImg: false,
      item: {
        name: '',
        logo: '',
        site: '',
        type: '',
        description: '',
        email: '',
        address: '',
        phone: ''
      },
    }
  },
  methods: {
    saveItem() {
      let error = false;

      if (!error) {
        this.loading = true;
        let self = this;

        let itemToSave = Object.assign({}, this.item);
        itemToSave.content = JSON.stringify(itemToSave.content);

        axios.post(this.$apiUrl + 'membership-club/create', itemToSave)
        .then(response => {
          self.loading = false;
          this.$alert(response.data.message, 'Sucesso', 'success').then(() => {
            self.$router.push('/clube-de-servicos');
          });
        })
        .catch(error => {
          this.$alert(error.response.data.message, 'Erro', 'error');
          console.log(error.response.data.error);
          self.loading = false;
        });
      }
    },
    async imgChanged() {
      let self = this;
      let file = this.$refs.img.files[0];
      self.savingImg = true;

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        axios.post(this.$apiUrl + 'files/upload', formData)
                .then(response => {
                  self.item.logo = response.data.file.key;
                  self.savingImg = false;
                })
                .catch(error => {
                  console.log(error);
                  self.savingImg = false;
                });
      }
    },
    formatURL() {
      this.item.url = this.item.url.replace(/\s+/g, '-').toLowerCase();
    },
  },
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/global";
  @import "../../assets/scss/buttons";

  .pages {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px;
    position: relative;

    .back-btn {
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 10px;
    }

    .wrapper {
      padding: 50px 200px;
      @media screen and (max-width: 800px) {
        padding: 50px;
      }
    }

    label {
      display: block;
      text-align: left;

      &:nth-of-type(1) {
        margin-top: 10px;
      }
    }

    input, select, textarea {
      padding: 10px;
      width: 100%;
      border: 1px solid $primary;
      border-radius: 10px;
      font-size: 1rem;
    }

    select {
      -webkit-appearance: none;
    }

    .save-btn {
      border-radius: 10px;
    }

    img {
      max-width: 50%;
      height: auto;
    }

    .editor-wrapper {
      text-align: left;
      background-color: #fff;
      border: 1px solid $primary;
      border-radius: 10px;
    }
  }
</style>
